import { cn } from '@/lib/utils';
import defaultImg from "@public/logos/logo-square-color.png";
import { useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

export default function LazyImg({ src = "", onClick = () => { }, className = "", style = {} }) {
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [imageSrc, setImageSrc] = useState(defaultImg);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!visible && isInView) {
            setImageSrc(src);
            setVisible(true);
        }
    }, [isInView]);

    return <img style={style} onClick={onClick} src={imageSrc} ref={ref} className={cn("flex flex-col justify-center items-center", className)} />
}

